<template>
  <v-row class="project-configuration pt-6">
    <v-col
      v-if="hideSidebar"
      class="project-configuration__sidebar py-0"
      md="3">
      <v-list
        class="pa-0"
        color="transparent"
        dense
        nav>
        <v-subheader>
          {{ $t('project') }}
        </v-subheader>
        <v-list-item
          v-for="({ title, icon, to }, index) of navItems"
          :key="index"
          :to="to">
          <v-list-item-action>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider class="mt-3 mb-2"/>

      <v-list
        class="pa-0"
        color="transparent"
        dense
        nav>
        <v-subheader>
          {{ $t('Environments') }}
        </v-subheader>
        <CyButton
          class="create-env-btn px-3 mb-1"
          variant="tertiary"
          theme="primary"
          height="40px"
          icon="add"
          lg
          :to="{
            name: 'addServiceEnv',
            params: { mode: 'add-env', backRouteTo: 'projectConfigurationGeneral' },
          }">
          {{ $t('environment.create') }}...
        </CyButton>
        <v-list-item
          v-for="({ canonical, id, icon, color }) of _.orderBy(envs, 'created_at', 'asc')"
          :key="id"
          :to="{ name: 'projectConfigurationEnvironment', params: { envCanonical: canonical } }">
          <v-list-item-action>
            <CyAvatar
              :item="{ icon, color }"
              sm/>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ canonical }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>

    <v-col md="9">
      <router-view/>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageProjectConfiguration',
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
  },
  breadcrumb () {
    const { projectCanonical, projectName } = this
    return constructBreadcrumb(this.$options.name, this.$t('routes.projectConfiguration'), [
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    return { title: this.$t('routes.projectConfiguration') }
  },
  computed: {
    ...mapGetters('organization/project', [
      'envs',
    ]),
    navItems () {
      return [{
        title: this.$t('general'),
        icon: 'settings',
        to: { name: 'projectConfigurationGeneral' },
      },
      {
        title: this.$t('routes.logs'),
        icon: 'troubleshoot',
        to: { name: 'projectConfigurationLogs' },
      }]
    },
    hideSidebar () {
      return !_.includes(this.$route.name, 'ElasticSearch') && !_.includes(this.$route.name, 'InfraView')
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.projectConfiguration',
      },
      es: {
        title: '@:routes.projectConfiguration',
      },
      fr: {
        title: '@:routes.projectConfiguration',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.project-configuration {
  &__sidebar {
    .v-divider {
      border-color: get-color("primary", "light-5") !important;
    }
  }

  .create-env-btn {
    display: flex;
    justify-content: flex-start;
    font-weight: $font-weight-normal !important;

    &:hover {
      background: get-color("primary", "light-5") !important;
    }
  }
}

::v-deep .v-list {
  .v-subheader {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 120%;
    text-transform: uppercase;
  }

  &-item__action {
    margin-right: 8px !important;
  }

  &-item__title {
    line-height: 1.5 !important;
  }

  .v-btn.v-btn.cy-btn {
    padding: 0 8px;
  }
}
</style>
